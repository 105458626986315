import { toast } from 'react-toastify';

export const errorToast = (msg: string) => {
  toast.error(msg);
};

export const successToast = (msg: string) => {
  toast.success(msg);
};

export const warningToast = (msg: string) => {
  toast.warning(msg);
};

export const payfortToast = (header: string, message: string, status = true) => {
  const toastOptions = {
    autoClose: 3000,
  };

  if (status) {
    toast.success(
      <div>
        <strong>{header}</strong>
        <div>{message}</div>
      </div>,
      toastOptions,
    );
  } else {
    toast.error(
      <div>
        <strong>{header}</strong>
        <div>{message}</div>
      </div>,
      toastOptions,
    );
  }
};
