import React, { useMemo, useState, useEffect, useContext } from 'react';
import { event } from 'components/GoogleTagManager';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import Link from 'next/link';
import PharmacyImage from 'components/PharmacyImage';
import { StoreCreditIcon } from 'components/SvgIcons/StoreCreditIcon';
import useSessionStorage from 'hooks/useSessionStorage';
import User from './User';
import Cart from './Cart';
import Navigation from 'components/Navigation';
import BackIcon from 'public/image/back-icon.svg';
import AutoComplete from 'components/Autocomplete';
import { DeviceDetector } from 'utils/dd';
import { truncateText } from 'lib/helpers/functions/TextTruncate';
import { useGetCatalog } from 'queries/useGetCatalog';
import { isArray } from 'lodash';
import { treeView } from 'utils/category-tree';
import useRtl from 'hooks/useRtl';
import { LanguageType, LanguageTypeShort } from 'lib/constants';
import clsx from 'clsx';
import { MetaTag, OpenGraph, Twitter } from 'next-seo/lib/types';
import { hasCategoryFilters, removeFiltersFromURL } from 'utils/convert-algolia-facets';
import dynamic from 'next/dynamic';
import Notification from './Notification';

// const Notification = dynamic(() => import('./Notification'), { ssr: false });

const CommonHeader = ({
  subHeader,
  t,
  title = 'Dr. Sulaiman Al Habib Pharmacy  | صيدلية د. سليمان الحبيب',
  description = 'A pharmacy website',
  openGraph,
  metaTags,
  canonical,
  twitter,
}: {
  subHeader: boolean;
  t: any;
  title?: string;
  canonical?: string;
  description?: string;
  openGraph?: OpenGraph;
  metaTags?: MetaTag[];
  twitter?: Twitter;
}) => {
  const { asPath, locale, pathname, push, back, query } = useRouter();
  const [showSearch, setShowSearch] = useState(true);
  const [, setIsMap] = useSessionStorage('storeLocation', false);
  const isMobile = useContext(DeviceDetector).isMobile;
  const [isCheckout, setIsCheckout] = useState(
    ['/checkout/delivery-method', '/checkout/payment-method', '/checkout/purchase', '/checkout', '/category'].includes(
      pathname,
    ),
  );
  const [isCat, setIsCat] = useState(['/category/[id]'].includes(pathname));
  const isProfile = pathname.includes('/profile');
  const { data } = useGetCatalog(isCat);
  const isRtl = useRtl();
  const validCategories = isArray(data) ? data?.filter((item: any) => item?.product_count !== 0) : [];

  const { id } = query;
  let paymentName = '';

  if (pathname?.includes('payment-method')) {
    paymentName = 'payment-method';
  }
  let CategoryName = '';
  let path = <></>;
  if (typeof id === 'string') {
    if (id?.includes('-C-')) {
      const [Catname, CatId] = id?.split('-C-');
      CategoryName =
        Catname?.split('-')
          .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
          })
          ?.join(' ') ?? '';
      path = treeView(CatId, validCategories);
    } else if (id?.includes('keyword=')) {
      CategoryName = id?.split('=')[1];
    }
  }

  useEffect(() => {
    if (['/checkout/payment-method', '/checkout/purchase', '/category'].includes(pathname)) {
      setIsCheckout(true);
    } else if (['/checkout'].includes(pathname) && isMobile) {
      setIsCheckout(true);
    } else {
      setIsCheckout(false);
    }
    if (['/category/[id]'].includes(pathname)) {
      setIsCat(true);
    } else {
      setIsCat(false);
    }
  }, [pathname]);

  const renderIsCheckout2 = useMemo(() => {
    return (
      <div className='flex flex-row-reverse items-center justify-end px-3'>
        <div className='items-center justify-center inline px-2 mb-1 '>
          <button aria-label='search' onClick={() => setIsMap(true)}>
            <StoreCreditIcon width='30' height='30' />
          </button>
        </div>
        <button
          onClick={() => {
            push(asPath, asPath, { locale: isRtl ? LanguageType.En : LanguageType.Ar }).finally(() => {
              window.location.reload();
            });
            event('language_change', {
              language: !isRtl ? LanguageTypeShort.ArShortSmall : LanguageTypeShort.EnShortSmall,
            });
          }}
          className='items-start justify-start cursor-pointer lg:flex'
        >
          <PharmacyImage
            width={36}
            height={27}
            objectFit='fill'
            src={!isRtl ? '/local/ar-n.png' : '/local/static/images/en.png'}
            localImage
            alt={!isRtl ? LanguageTypeShort.ArShortCap : LanguageTypeShort.EnShortCap}
          />

          <span className='mx-1 align-[3px] hidden md:inline'>
            {!isRtl ? LanguageType.ArWord : LanguageTypeShort.EnShortCap}
          </span>
        </button>
      </div>
    );
  }, [isCheckout, showSearch, asPath, locale, isMobile]);

  const CheckoutMenu = () => {
    return useMemo(() => {
      if (isMobile) return null;

      const commonClasses = 'hidden lg:flex md:flex items-center';

      return (
        <span className={clsx(isRtl && 'hidden mr-[10%] justify-end xl:min-w-[50vh] lg:min-w-[30vh]', commonClasses)}>
          <Cart />
          <User />
          <Notification />
          <div className='rtl:mr-2 ml-2'>
            <button
              aria-label='language'
              onClick={() => {
                const newURL = hasCategoryFilters(asPath) ? removeFiltersFromURL(asPath) : asPath;
                push(newURL, newURL, { locale: isRtl ? LanguageType.En : LanguageType.Ar }).finally(() => {
                  window.location.reload();
                });
                event('language_change', {
                  language: !isRtl ? LanguageTypeShort.ArShortSmall : LanguageTypeShort.EnShortSmall,
                });
              }}
              className='flex items-start justify-start cursor-pointer'
            >
              <PharmacyImage
                width={36}
                height={27}
                objectFit='fill'
                src={!isRtl ? '/local/ar-n.png' : '/local/static/images/en.png'}
                localImage
                alt={!isRtl ? LanguageTypeShort.ArShortCap : LanguageTypeShort.EnShortCap}
              />
              <span className='px-1 pt-1'>{!isRtl ? LanguageType.ArWord : LanguageTypeShort.EnShortCap}</span>
            </button>
          </div>
        </span>
      );
    }, [isMobile]);
  };

  const renderIsCheckoutMenu = useMemo(() => {
    return <CheckoutMenu />;
  }, [isMobile, isRtl, locale, asPath, pathname]);

  const renderAutoSearch = useMemo(() => {
    if (isMobile) return undefined;
    return (
      <div
        className={`hidden rtl:pr-10  lg:block lg:col-start-9 lg:col-span-7 md:col-span-7 sm:col-start-1 sm:col-span-2 text-center  lg:text-left sm:text-center`}
      >
        <div className='flex mx-5'>
          <AutoComplete show={true} />
        </div>
      </div>
    );
  }, [isCheckout, isMobile]);

  const renderBackButton = useMemo(() => {
    return (
      <div className='px-2 pt-1 text-left'>
        <button
          onClick={() => (pathname === '/checkout/payment-method' ? push('/checkout/delivery-method') : back())}
          className='rtl:rotate-180'
          name='backBtn'
        >
          <BackIcon />
        </button>
      </div>
    );
  }, [isCheckout, isMobile]);

  if (isProfile && isMobile) {
    return <></>;
  }
  if (pathname === '/links') {
    return <></>;
  }
  return (
    <>
      <NextSeo
        title={title}
        defaultTitle='Dr. Sulaiman Al Habib Pharmacy  | صيدلية د. سليمان الحبيب'
        description={description}
        openGraph={openGraph}
        additionalMetaTags={metaTags}
        canonical={canonical}
        twitter={twitter}
      />
      <div className=''>
        <div className='full-width'>
          {/* {!isCheckout && renderIsCheckout} */}
          <div
            className={clsx(
              'bg-apollo-white lg:h-[106px] h-auto border-t-2 border-grey grid lg:grid-cols-24 md:grid-cols-2 grid-cols-1 items-center',
              { 'border-b-2': !subHeader },
              { hidden: isCheckout && !isMobile },
            )}
          >
            <div className='text-center 2xl:col-start-3 2xl:col-span-6 xl:col-start-3 xl:col-span-6 lg:col-start-3 lg:col-span-6 md:col-start-1 md:col-span-2 sm:col-start-1 sm:col-span-2 2xl:text-left xl:text-left lg:text-left rtl:2xl:text-right rtl:xl:text-right rtl:lg:text-right md:text-center sm:text-center'>
              {/* for mobile view starts here */}
              {isMobile && (
                <span className='grid grid-cols-3 mt-2 lg:hidden'>
                  <div className='inline-block pt-1'>
                    {isCheckout ? (
                      isMobile ? (
                        renderBackButton
                      ) : (
                        <></>
                      )
                    ) : isCat && isMobile ? (
                      renderBackButton
                    ) : (
                      renderIsCheckout2
                    )}
                  </div>
                  <span className=''>
                    {isMobile && isCat ? (
                      <span className='text-[17px] w-[100px] font-bold text-dark-gray'>
                        {truncateText(CategoryName?.toUpperCase(), 50)}
                      </span>
                    ) : paymentName && isMobile ? (
                      <span className='text-[17px] w-[81px] font-bold  text-[#595959]'>
                        {paymentName && t('payment')}
                      </span>
                    ) : (
                      <Link href={'/'} prefetch={false}>
                        <PharmacyImage
                          className='inline mb-1 cursor-pointer'
                          width={40}
                          height={40}
                          minHeight={40}
                          objectFit='contain'
                          src='/local/android-chrome-512x512.png'
                          localImage
                          alt={LanguageTypeShort.ArShortCap}
                        />
                      </Link>
                    )}
                  </span>
                  <span className='px-0 text-right absolute right-0 rtl:right-[85%] pt-1 rtl:text-left'>
                    {!isCheckout && (
                      <>
                        <Notification />
                      </>
                    )}
                  </span>
                </span>
              )}
              {/* for mobile view ends here */}
              {/* for web view starts here */}
              {isRtl && (
                <>
                  {!isCheckout && (
                    <div
                      className={`lg:col-start-19 ml-[30%] lg:col-span-5 md:col-span-2 sm:col-start-1 sm:col-span-1 text-center order-first lg:order-last sm:order-first hidden 2xl:block xl:block lg:block md:hidden sm:hidden`}
                    >
                      <Link href={'/'} prefetch={false}>
                        <PharmacyImage
                          className='inline mb-1 cursor-pointer'
                          width={272}
                          height={64}
                          objectFit='fill'
                          src='/local/static/images/logo@text.svg'
                          localImage
                          alt={LanguageTypeShort.ArShortCap}
                        />
                      </Link>
                    </div>
                  )}
                </>
              )}
              <div className='hidden lg:flex'>{!isCheckout && !isRtl && renderIsCheckoutMenu}</div>

              {/* for web view ends here */}
            </div>
            {isCat && isMobile && <div>{path}</div>}
            {/* For Web View */}
            {!isCheckout && renderAutoSearch}
            <div className='hidden lg:flex'>{!isCheckout && isRtl && renderIsCheckoutMenu}</div>
            {/* For Mobile View */}
            {!isCheckout && isMobile && (
              <div
                className={clsx(
                  'lg:hidden lg:col-start-9 lg:col-span-7 md:col-span-2 sm:col-start-1 sm:col-span-2 text-center lg:text-left sm:text-center',
                  { 'mb-5': showSearch },
                )}
              >
                <div className='flex mx-5'>{<AutoComplete show={showSearch} />}</div>
              </div>
            )}
            <div className='lg:col-start-16 lg:col-span-2 md:col-span-2 sm:col-start-1 sm:col-span-1'></div>
            {!isRtl && (
              <>
                {!isCheckout && (
                  <div className='order-first hidden text-center lg:col-start-19 lg:col-span-5 md:col-span-2 sm:col-start-1 sm:col-span-1 lg:order-last sm:order-first 2xl:block xl:block lg:block md:hidden sm:hidden'>
                    <Link href={'/'} prefetch={false}>
                      <PharmacyImage
                        className='inline mb-1 cursor-pointer'
                        width={272}
                        height={64}
                        objectFit='fill'
                        src='/local/static/images/logo@text.svg'
                        localImage
                        alt='AR'
                      />
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>

          <div
            className={clsx(
              'hidden',
              { 'md:flex-row-reverse': !isRtl },
              'px-3 md:flex lg:hidden xl:hidden 2xl:hidden h-[106px] justify-between bg-[#fff] items-center',
            )}
          >
            <Link href={'/'} prefetch={false}>
              <PharmacyImage
                className='inline mb-1 cursor-pointer'
                width={200}
                height={44}
                objectFit='fill'
                src='/local/static/images/logo@text.svg'
                localImage
                alt='AR'
              />
            </Link>
            <div>
              <AutoComplete show={true} />
            </div>
            {renderIsCheckoutMenu}
          </div>

          {isCheckout && !isMobile && (
            <div
              className={clsx(
                'bg-apollo-white md:h-[106px] lg:h-[106px] border-t-2 border-grey flex justify-center items-center',
                { 'border-b-2': !subHeader },
              )}
            >
              <Link href={'/'} prefetch={false}>
                <PharmacyImage
                  className='inline mb-1 cursor-pointer'
                  width={272}
                  height={64}
                  objectFit='fill'
                  src='/local/static/images/logo@text.svg'
                  localImage
                  alt='AR'
                />
              </Link>
            </div>
          )}
          {(subHeader || ((pathname === '/checkout' || pathname === '/checkout/delivery-method') && !isMobile)) && (
            <Navigation />
          )}
        </div>
      </div>
    </>
  );
};

export default CommonHeader;
