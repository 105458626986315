import { payfort_validate } from 'models/cart';
import { errorToast, payfortToast, successToast } from 'lib/taost';

export default function Payfort(callback?: any, t?: any, cartData?: any) {
  const validate = async (push?: any, locale?: string) => {
    const queryString = getQueryString();
    payfort_validate(queryString)
      .then((response) => {
        console.log(response);
        if (response?.redirect) {
          window.location.href = response.url;
        } else if (!response?.status) {
          // failure
          callback(true);
          push(`/${locale}/checkout/payment-method`);
          payfortToast(
            t(`payfort_status_${response?.status_code}`),
            t(`payfort_message_${response?.message_code}`),
            false,
          );
          return response;
        } else if (response?.status) {
          // success
          payfortToast(
            t(`payfort_status_${response?.status_code}`),
            t(`payfort_message_${response?.message_code}`),
            true,
          );
          push(`/${locale}/checkout/purchase`);
        }
      })
      .catch((e) => {
        payfortToast(t('unknown_payfort_status'), t('unknown_payfort_message'), false);
        console.log(e);
        window.location.href = `/${locale}/checkout/payment-method`;
        // window.location.href = `/checkout/payment-method`;
      });
  };

  const getQueryString = () => {
    let queryString = window?.location?.search?.replace(/^\?/, '');
    queryString = decodeURIComponent(queryString);
    return queryString;
  };
  const isPayfortRequest = () => {
    const url = getQueryString();
    return url?.indexOf('signature') > -1 && url.indexOf('target_url') > -1;
  };

  const redirect = (result: any, activeCard: any, locale: any) => {
    // debugger;
    let params;
    if (result?.payfort_param && result?.payfort_param.length > 0) params = result?.payfort_param[0];
    const cardParams = activeCard ? formatCardParams(activeCard) : {};
    const newParams = { ...cardParams, ...params };
    let jsToExecute = `javascript:
          var createInput=function(name,value){
            var formEl=document.createElement('input');
            formEl.setAttribute('type','hidden');
            formEl.setAttribute('name',name);
            formEl.setAttribute('value',value);
            form.append(formEl)};
            var form=document.createElement('form');  
            form.setAttribute('method','post');
            form.setAttribute('action','${result.url}');
            createInput('language', 'PAYFORT_LANGUAGE');
            createInput('merchant_identifier', 'PAYFORT_MERCHANT_IDENTIFIER');
            createInput('access_code', 'PAYFORT_ACCESS_CODE');
            createInput('merchant_reference', 'PAYFORT_MERCHANT_REFERENCE');
            createInput('service_command', 'PAYFORT_SERVICE_COMMAND');
            createInput('return_url', 'PAYFORT_RETURN_URL');
            createInput('signature', 'PAYFORT_SIGNATURE');
            createInput('card_number', 'PAYFORT_CARD_NUMBER');
            createInput('expiry_date', 'PAYFORT_EXPIRY_DATE');
            createInput('card_security_code', 'PAYFORT_CARD_SECURITY_CODE');
            createInput('card_holder_name', 'PAYFORT_CARD_HOLDER_NAME');
            createInput('remember_me', 'PAYFORT_REMEMBER_ME');
            document.getElementsByTagName('body')[0].appendChild(form);
            form.submit();`;
    for (const key in newParams) {
      jsToExecute = jsToExecute.replace(`PAYFORT_${key.toUpperCase()}`, newParams[key]);
    }
    if (params) window.location.href = jsToExecute;
    else window.location.href = result.url;
  };

  const formatCardParams = (params: any) => {
    // debugger;
    const [month, year] = params?.expiry_date.split('/') ?? [];
    const expiry_date = `${year}${month}`;
    return {
      card_number: +`${params?.card_number}`.replace(/[^\d]/g, ''),
      card_security_code: +params?.card_security_code,
      expiry_date: +expiry_date,
      card_holder_name: params?.card_holder_name,
      remember_me: params?.remember_me ? 'YES' : 'NO',
    };
  };

  return {
    redirect,
    isPayfortRequest,
    getTargetUrl: getQueryString,
    validate,
    formatCardParams,
  };
}
